var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
            name: 'DashboardHome',
          }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'SystemSettingsHome',
            }}},[_vm._v("全系統管理")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("組織管理")])],1)],1)])]),_c('b-card',[_c('h4',{staticClass:"mb-2 mb-xl-0 mr-2 font-weight-bold"},[_vm._v("組織管理")]),_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('div',{staticClass:"col-12 col-xl-3"},[_c('b-form-checkbox',{staticClass:"pt-2",on:{"change":_vm.fetchOrganizations},model:{value:(_vm.onlyEnabled),callback:function ($$v) {_vm.onlyEnabled=$$v},expression:"onlyEnabled"}},[_vm._v(" 只包含已啟用 ")])],1),_c('div',{staticClass:"col-12 col-xl-6 d-flex flex-column flex-xl-row align-items-start align-items-xl-center"},[(_vm.checkPermission([_vm.consts.ORGANIZATION_MODIFY]))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-2",attrs:{"variant":"primary"},on:{"click":_vm.handleCreate}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增組織 ")]):_vm._e(),_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"搜尋組織名稱或組織編號"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":_vm.handleSearch}},[_c('i',{staticClass:"fa fa-search"})])],1)],1)],1)]),_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.organizations,"fields":_vm.fields,"busy":_vm.isLoading},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"variant":"inverse-secondary","to":{
            name: 'OrganizationShow',
            params: {
              orgId: data.item.id,
            },
          }}},[_vm._v("檢視")]),(_vm.checkPermission([_vm.consts.ORGANIZATION_MODIFY]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-warning","to":{
            name: 'OrganizationEdit',
            params: {
              orgId: data.item.id,
            },
          }}},[_vm._v("編輯")]):_vm._e(),(_vm.checkPermission([_vm.consts.ROLE_LIST_VIEW]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-primary","to":{
            name: 'OrgRoleList',
            params: {
              orgId: data.item.id,
            },
          }}},[_vm._v("角色權限管理")]):_vm._e(),(_vm.checkPermission([_vm.consts.MERCHANT_LIST_VIEW]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-info","to":{
            name: 'OrgMerchantList',
            params: {
              orgId: data.item.id,
            },
          }}},[_vm._v("通路清單")]):_vm._e(),(_vm.checkPermission([_vm.consts.ORGANIZATION_DELETE]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"danger","disabled":!_vm.canDelete(data.item)},on:{"click":function($event){return _vm.handleDelete(data.item.id, data.item.name, data.item.code)}}},[_vm._v("刪除")]):_vm._e()]}}])}),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"80px"}},[_c('b-pagination',{staticClass:"separated",attrs:{"total-rows":_vm.total,"per-page":_vm.perPage,"aria-controls":"organizationList"},on:{"change":function (page) { return _vm.fetchOrganizations(page); }},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }